export enum ActivitySubTypeOptions {
  ReadAloud = 'Read aloud',
  StoryBook = 'Story book',
  Other = 'Other',
}

export enum ActivityTypeOptions {
  SmallGroup = 'Small group',
  LargeGroup = 'Large group',
}

export enum ActivityShareOptions {
  Yes = 'Yes',
  No = 'No',
  NA = 'N/A',
}
