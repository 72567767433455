export enum StoryBookTypeOptions {
  ReadAloud = 'Read aloud',
  StoryBook = 'Story book',
  Other = 'Other',
}

export enum StoryBookThemeOptions {
  Nature = 'Nature',
  NoTheme = 'No theme',
}

export enum StoryBookShareOptions {
  Yes = 'Yes',
  No = 'No',
  NA = 'N/A',
}
